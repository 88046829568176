import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	imageAlt: {
		id: 'profile-macros.renderer.image.alt',
		defaultMessage: 'User icon',
		description: 'Message for image alternative text that describes that this is a user icon',
	},
	anonymous: {
		id: 'profile-macros.renderer.image.alt.anonymous',
		defaultMessage: 'Anonymous',
		description: 'Message for image alternative text that describes that this is an anonymous user',
	},
	error: {
		id: 'profile-macros.renderer.image.error',
		defaultMessage: 'We’re unable to display this profile picture. Refresh to try again',
		description: "Message describing there's been an error",
	},
	infoError: {
		id: 'profile-macros.renderer.info.error',
		defaultMessage: 'We’re unable to display this user’s info. Refresh to try again',
		description: "Message describing there's been an error",
	},
	noAccess: {
		id: 'profile-macros.renderer.no.access.message',
		defaultMessage: 'The content of this macro can only be viewed by users who have logged in.',
		description: 'Message shown to user if they have no profile access',
	},
	emailTitle: {
		id: 'profile-macros.renderer.email.title',
		defaultMessage: 'Send Email to',
		description: 'Message telling the user to send email',
	},
	deactivatedUser: {
		id: 'profile-macros.renderer.deactivated.user',
		defaultMessage: '{userFullName} (Deactivated)',
		description:
			'userFullName will be the name e.g. John Doe and this will show that the user is deactivated',
	},
});
