import gql from 'graphql-tag';

export const UserContextQuery = gql`
	query UserContextQuery($userId: ID) {
		user(id: $userId) {
			id
			photos {
				value
				isPrimary
			}
			displayName
			emails {
				value
				isPrimary
			}
			title
			isActive
		}
	}
`;
