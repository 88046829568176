import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import Avatar from '@atlaskit/avatar';
import { Box, xcss } from '@atlaskit/primitives';

import { getAtlassianPeopleProfileUrl, ProfileAvatar } from '@confluence/profile';
import { Attribution, GenericErrorBoundary, ErrorDisplay } from '@confluence/error-boundary';
import type { ExtensionHandlerProps } from '@confluence/fabric-extension-lib/entry-points/fabric-extension-lib-types';
import { AccessStatus, useSessionData } from '@confluence/session-data';

import { i18n } from '../profileMacrosi18n';
import { useProfileInfo } from '../useProfileInfo';

export type ProfilePictureMacroRendererProps = {
	macroDefaultProps: ExtensionHandlerProps;
	macroParams?: {
		User?: { value?: string };
		Size?: { value?: string };
	};
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProfilePictureContainer = styled.div({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	lineHeight: 'normal !important',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	display: 'flex !important',
});

// Adding padding so that user has a touch target, otherwise everytime they click the macro,
// it will open the link to the profile.
const containerStyles = xcss({
	padding: 'space.075',
	display: 'inline-block',
});

// Bumping up the size by 1 for each to match original sizes
export const getAvatarSize = (macroSize?: string) => {
	switch (macroSize) {
		case 'extra large':
			return 'xxlarge';
		case 'large':
			return 'xlarge';
		default:
			return 'large';
	}
};

const ProfilePictureMacroRendererComponent = ({
	macroDefaultProps,
	macroParams,
}: ProfilePictureMacroRendererProps) => {
	const { formatMessage } = useIntl();
	const userId = macroParams?.User?.value;
	const macroSize = macroParams?.Size?.value;
	const { accessStatus } = useSessionData();

	const isAnonymousUser = accessStatus === AccessStatus.ANONYMOUS_ACCESS;

	const { hasViewUserProfilePermission, graphqlError, userName, iconUrl } = useProfileInfo(userId);

	const profileUrl = hasViewUserProfilePermission ? getAtlassianPeopleProfileUrl(userId) : '';
	const altText = `${formatMessage(i18n.imageAlt)}: ${userName}`;
	const dataMacroParameters = {
		User: userId,
		Size: macroSize,
	};

	const getAvatar = () => {
		// When trying to preload the query which fetches the profile info it is timing out :(
		// The workaround is just to use placeholders for the avatars in SSR and not show anything like before if the
		// user hasn't selected someone to view for the profile.
		if (process.env.REACT_SSR) {
			return userId ? (
				<Avatar appearance="circle" size={getAvatarSize(macroSize)} testId="placeholder-avatar" />
			) : null;
		}

		return (
			<a data-testid="profile-link" href={profileUrl} target="_blank">
				<ProfileAvatar
					userId={userId}
					src={iconUrl}
					size={getAvatarSize(macroSize)}
					name={altText}
					isAnonymous={isAnonymousUser}
					testId="profile-image"
				/>
			</a>
		);
	};

	return (
		<Box
			xcss={containerStyles}
			data-fabric-macro={macroDefaultProps?.attributes?.macroId}
			data-macro-body=""
			data-macro-parameters={JSON.stringify(dataMacroParameters)}
		>
			<ProfilePictureContainer
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="userLogoLink"
				data-username={userId}
				data-account-id={userId}
			>
				{graphqlError && <ErrorDisplay error={graphqlError} />}
				{getAvatar()}
			</ProfilePictureContainer>
		</Box>
	);
};

export const ProfilePictureMacroRenderer = (props: ProfilePictureMacroRendererProps) => {
	return (
		<GenericErrorBoundary
			renderOnError={() => <FormattedMessage {...i18n.error} />}
			attribution={Attribution.PAGE_EXTENSIONS}
		>
			<ProfilePictureMacroRendererComponent {...props} />
		</GenericErrorBoundary>
	);
};
