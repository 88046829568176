import { useQuery } from '@apollo/react-hooks';

import { useViewUserProfile } from '@confluence/global-operations';

import { UserContextQuery } from './UserContextQuery.graphql';
import type {
	UserContextQuery as UserContextQueryType,
	UserContextQueryVariables,
} from './__types__/UserContextQuery';

// Hook which returns info needed for the profile macros
export const useProfileInfo = (userId?: string) => {
	const { canViewUserProfile: hasViewUserProfilePermission } = useViewUserProfile();

	const { data, error } = useQuery<UserContextQueryType, UserContextQueryVariables>(
		UserContextQuery,
		{
			variables: {
				userId,
			},
			skip: !userId,
		},
	);

	return {
		graphqlError: error,
		hasViewUserProfilePermission,
		userName: data?.user?.displayName ?? '',
		iconUrl: data?.user?.photos?.[0]?.value ?? '',
		email: hasViewUserProfilePermission ? data?.user?.emails?.[0]?.value ?? '' : '',
		jobTitle: data?.user?.title ?? '',
		isActiveUser: data?.user?.isActive ?? true,
	};
};
